






import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import ModulesScreensWrapper from '@/components/Modules/modules/ModulesScreensWrapper.vue'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'

import { Component } from 'vue-property-decorator'

import Vue from 'vue'

@Component({
  components: {
    ModulesScreensWrapper,
    CenteredColumnLayout,
  },
})
export default class ChatActivitySupport extends Vue {

  activityModules = [
    ModuleTypesEnum.SendWakeupMessageModule,
    ModuleTypesEnum.SendAdminMessageModule,
    ModuleTypesEnum.EntertainmentHandlerModule,
    ModuleTypesEnum.EntertainmentCallHandlerModule,
    ModuleTypesEnum.ChatCryptoCommandHandlerModule,
    ModuleTypesEnum.InactiveKickHandlerModule,
    ModuleTypesEnum.FirstInactiveKickHandlerModule,
    ModuleTypesEnum.DailyReportModule
  ]

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }
}
